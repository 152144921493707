<template>
       <div class="contact-services">
      <div class="row">
        <div class="col-md-4 mb-3">
          <h3>
            {{ $t("Don't hesitate") }}
          </h3>
          <h1>{{ $t('To contact us !') }}</h1>
          <p>
            {{ $t('contat-desc') }}
          </p>
        </div>
        <div class="col-md-8">
          <Form class="form-contact" @submit="handelForm" :validation-schema="schema">
          <div class="row">
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="name" type="text" :placeholder="$t('FullName')" />
                <ErrorMessage name="name" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="company" type="text" :placeholder="$t('CompanyName')" />
                <ErrorMessage name="company" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field class="contact-field" name="email" type="email" :placeholder="$t('Email')" />
                <ErrorMessage name="email" class="error-message" />
              </div>
            </div>
            <div class="col-md-6 mb-2">
              <div class="field">
                <Field name="ReasonCommunication" v-slot="{field}">
                  <multiselect :searchable="false" v-model="value" v-bind="field" :options=" Reasons " @select="PickId "
                  :placeholder=" this.$i18n.locale == 'ar' ? 'اختر سبب التواصل' : 'Choose the reason' " label="text" track-by="text" selectLabel=""
                  :selectedLabel="this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'"
                  :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'"></multiselect>
                </Field>
                <ErrorMessage name="ReasonCommunication" class="error-message" />
              </div>
            </div>
            <div class="col-12 mb-2">
              <Field style="height: 100px;" class="contact-field" as="textarea" name="messege" :placeholder=" $t('messege') " />
              <ErrorMessage name="messege" class="error-message" />
              <div class="d-flex justify-content-end mt-3">
                <button class="btn-main" type="submit">{{ $t('Send') }}</button>
              </div>
            </div>
          </div>
        </Form>
        </div>
      </div>
     </div>
</template>
<script>
import axios from 'axios';
import { notify } from "@kyvg/vue3-notification";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { configure } from "vee-validate";
import Multiselect from 'vue-multiselect';
import Cookie from 'cookie-universal'
const cookie = Cookie()
export default {
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = yup.object({
      name: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل الاسم مطلوب' : 'name required').min(4,cookie.get('lang') == 'ar' ? 'يجب كتابة 4 أحرف على الاقل' : ' must be 4 letters min'),
      email: yup.string().required(cookie.get('lang') == 'ar' ? 'حقل البريد الإلكتروني مطلوب' : 'email required').email(cookie.get('lang') === 'ar' ? 'يجب إدخال بريد إلكتروني صالح' : 'email must be valid'),
      ReasonCommunication: yup.object().required(cookie.get('lang') == 'ar' ? 'اختيار السبب مطلوب' : 'reason required'),
      company: yup.string().required(cookie.get('lang') == 'ar' ? 'اسم الشركة مطلوب' : 'company name required').min(4,cookie.get('lang') == 'ar' ? 'يجب كتابة 4 أحرف على الاقل' : ' must be 4 letters min'),
      messege: yup.string().required(cookie.get('lang') == 'ar' ? 'يجب كتابة الرسالة' : 'messege required').min(10,cookie.get('lang') == 'ar' ? 'يجب كتابة 10 أحرف على الاقل' : 'messege must be 10 letters min'),
    });
    return {
      schema,
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      Reasons: [],
      value: '',
      reason_value:'',
      PhoneNumber: '',

    };
  },
  methods:{
    PickId () {
      this.reason_value = this.value.id;
      console.log(this.reason_value)
    },
    async handelForm(values) {
      const formData = {
        "name": values.name,
        "reason_id": this.reason_value,
        "email": values.email,
        "company": values.company,
        "msg": values.messege,
      }
      await axios.post(`/user/contact-messages`, formData)
        .then((res) => {
          if (res.status == 200) {
            this.$router.push('/thanks-contact')
          }
        })
        .catch(function (error) {
          if (error) {
            notify({
              type: "error",
              title: "خـطـأ !!",
              text: error?.response?.data?.message,
            });
          }
        });
    }
  },
  mounted() {
    this.$store.dispatch('GetContactReasons').then(r => {
      this.Reasons = this.$store.state.ContactReasons;
    });
  }

}
</script>
